@font-face {
  font-family: 'Moranga';
  font-weight: 100;
  src: local('Moranga'), url(./assets/fonts/Moranga/Latinotype\ -\ Moranga\ Light.otf) format('opentype');
}
@font-face {
  font-family: 'Moranga';
  font-weight: 200;
  src: local('Moranga'), url(./assets/fonts/Moranga/Latinotype\ -\ Moranga\ Light.otf) format('opentype');
}
@font-face {
  font-family: 'Moranga';
  font-weight: 300;
  src: local('Moranga'), url(./assets/fonts/Moranga/Latinotype\ -\ Moranga\ Regular\ -\ Copy.otf) format('opentype');
}
@font-face {
  font-family: 'Moranga';
  font-weight: 400;
  src: local('Moranga'), url(./assets/fonts/Moranga/Latinotype\ -\ Moranga\ Regular\ -\ Copy.otf) format('opentype');
}
@font-face {
  font-family: 'Moranga';
  font-weight: 500;
  src: local('Moranga'), url(./assets/fonts/Moranga/Latinotype\ -\ Moranga\ Medium.otf) format('opentype');
}
@font-face {
  font-family: 'Moranga';
  font-weight: 600;
  src: local('Moranga'), url(./assets/fonts/Moranga/Latinotype\ -\ Moranga\ Medium.otf) format('opentype');
}
@font-face {
  font-family: 'Moranga';
  font-weight: 700;
  src: local('Moranga'), url(./assets/fonts/Moranga/Latinotype\ -\ Moranga\ Bold.otf) format('opentype');
}
@font-face {
  font-family: 'Moranga';
  font-weight: 700;
  src: local('Moranga'), url(./assets/fonts/Moranga/Latinotype\ -\ Moranga\ Bold.otf) format('opentype');
}

@font-face {
  font-family: 'Moranga';
  font-weight: 800;
  src: local('Moranga'), url(./assets/fonts/Moranga/Latinotype\ -\ Moranga\ Black.otf) format('opentype');
}

input,
button {
  font-family: verdana, ubuntu;
}

.container {
  display: flex;
  flex-direction: row;
  min-height: 100%;
  overflow: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.chat-list {
  min-width: 240px;
  max-width: 380px;
  overflow: auto;
}

.right-panel {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.message-list {
  flex: 1;
  min-width: 140px;
  overflow: auto;
}

.input-area {
  height: 50px;
  background: red;
}

.on-drag-mlist {
  width: 100%;
  height: 100%;
  background-color: #e2f3f5;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.rce-example {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.rce-example .rce-example-btn {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 2rem 0;
  border-bottom: 1px solid rgb(214, 213, 213);
  width: 100%;
}

.rce-example .rce-example-btn button {
  margin: 0 0.5rem 0.5rem 0.5rem;
  padding: 0.5rem;
  border-radius: 0.5rem;
  cursor: pointer;
  font-weight: 600;
  border: none;
}

.rce-example .rce-example-btn button:first-child {
  margin-left: 0;
}

.rce-example .rce-example-btn button:last-child {
  margin-right: 0;
}

.rce-example-component {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60%;
}

.rce-example-messageList {
  background: rgb(246 250 250);
  border: 1px solid rgb(238, 235, 235);
  height: 70vh;
}

.rce-example-input textarea {
  border: 1px solid rgb(214, 213, 213);
  border-radius: 0.5rem;
  overflow: hidden;
}

.rce-mlist {
  padding: 1rem 0;
}